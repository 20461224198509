<template>
    <main id="main">
        <slot />
    </main>
</template>

<style lang="scss" scoped>
    #main {
        min-height: 100vh;
    }
</style>
